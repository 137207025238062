<template>
  <div v-loading="loading">
    <div class="all-template-container">
      <el-tabs
        v-if="allTemplates.length > 1"
        v-model="currentTemplate"
        @tab-click="changeTemplate"
        class="ml-1"
      >
        <el-tab-pane
          v-for="(template, i) in allTemplates"
          :label="getStepsObject[template.slug].senderAlias || template.name"
          :key="template._id + i"
          :name="template._id"
        >
        </el-tab-pane>
      </el-tabs>
      <el-row class="template-error-body" v-if="showError">
        <p>{{ showErrorMessage }}</p>
      </el-row>
      <el-row class="template-body" v-else>
        <entity-template-view
          v-if="showData && getCurrentStep"
          :allTemplatesLength="allTemplates.length"
          :templateData="getCurrentStep.templateInfo"
          :formbuilderDetails="formbuilderDetails"
          :has-next="getCurrentStep.has_next"
          :entityDataId="entityDataId"
          :workflow-step-id="getCurrentStep._id"
          :template-data-id="getCurrentStep.templateDataId"
          :currentStep="getCurrentStep"
          :is-execute="isEdit"
          :layout="layout"
          :currentEntity="currentEntity"
          :isEdit="isEdit"
          :entityData="entityData"
          :currentMenu="currentMenu"
          :hideActions="true"
          :isApprovalForm="checkApprovalFormBuilder"
          :currentFormbuilderData="currentFormbuilderData"
          :isUpdatingFormbuilderData="checkFormbuilderEntityDataUpdate"
          :suggestionEntityForm="currentStepData.default_values"
          v-on:goto-next="moveToNextTemplate"
          v-on:fetch-entity-data="fetchNewEntityData"
          v-on:entity-data-created="entityDataCreated"
          v-on:update-relationship="updateRelationData"
          :companyDetails="companyDetails"
          :isFormbuilderParent="true"
          :isFromFormBuilder="true"
          :childTableApprovalPermissions="
            formbuilderDetails.approval_child_entity_tables || []
          "
          :getSuggestionFormData="getSuggestionFormData"
        >
        </entity-template-view>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavigationHelper from "@/mixins/navigationHelper";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import { makeSlug } from "@/helpers/appHelper";
import { fetchEntityRelationships } from "@/repo/entityRelationshipsRepo";
export default {
  props: [
    "currentEntity",
    "isEdit",
    "layout",
    "formbuilderDetails",
    "currentFormbuilderData",
    "currentStepIndex",
    "currentStepData",
    "companyDetails",
    // "isApplicationUserSide",
  ],
  data() {
    return {
      loading: false,
      currentStep: 0,
      currentTemplate: "",
      showData: false,
      showError: false,
      showErrorMessage: "Invalid step",
      isApplicationUserSide: false,
      relationshipsData: [],
      entityDataId: "",
      currentEntityData: null,
      unAssignedFieldDetails: [],
      form: {},
      fetchedRelationships: null,
    };
  },
  watch: {
    activeTab() {
      if (this.activeTab?.template_id) {
        this.currentTemplate = this.activeTab.template_id;
      }
    },
    getEntityAllRelationshipData() {
      this.setEntityRelationshipData();
    },
  },
  mixins: [NavigationHelper, entityRelationshipMixin],
  components: {
    EntityTemplateView: () =>
      import("@/components/templates/templateFieldsPreview"),
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDataById",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityDocumentsNotIncludingTemplatesData",
      "getCompanyEntityDetails",
      "getEntityPrimaryData",
    ]),
    ...mapGetters("filters", ["getSingleFilterData", "getAllEntityFilters"]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
    ...mapGetters("menuManagementV2", ["getEntityMenu"]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getDefaultDateFormat"]),
    ...mapGetters("formbuilderData", [
      "getFormbuilderDataCreateByTemplateStatus",
      "getNewFormbuilderData",
      "getNewFormbuilderDataError",
      "getUserFormbuilderData",
      "getGuestUserFormbuilderSubmissions",
      "getGuestUserFormbuildersList",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
      "getEntityAllRelationshipData",
      "getEntityRelationshipDataAssignStatus",
    ]),
    ...mapGetters("templates", ["getSuggestionFormData"]),
    fieldsData() {
      if (this.getCurrentStep?.templateInfo?.sections[0]?.fields) {
        return this.getCurrentStep?.templateInfo.sections[0].fields;
      }
      return [];
    },
    currentSlug() {
      if (this.activeTab?.templateInfo?.slug) {
        return this.activeTab?.templateInfo?.slug;
      }
      return "";
    },
    activeTab() {
      if (
        this.getCurrentEntityTemplates &&
        this.getCurrentEntityTemplates[this.currentStep]
      ) {
        return this.getCurrentEntityTemplates[this.currentStep];
      }
      return null;
    },
    getCurrentStep() {
      if (this.getStepsObject && this.currentSlug) {
        const step = this.getStepsObject[this.currentSlug];
        if (step && step.type !== "TEMPLATE") {
          return step;
        }
        return step;
      }

      return null;
    },
    getStepsObject() {
      if (
        this.getCurrentEntityTemplates &&
        this.getCurrentEntityTemplates.length
      ) {
        const data = {};
        this.getCurrentEntityTemplates.forEach((step, index, arr) => {
          // it will use to determine finish step
          step.has_next = index < arr.length - 1;
          data[step.templateInfo.slug] = step;
        });
        return data;
      }
      return {};
    },
    currentMenu() {
      if (
        this.isApplicationUserSide &&
        this.$route?.query?.menu_id &&
        this.getEntityMenu?.menu_list
      ) {
        let menu;
        let id = this.$route.params.menuId || this.$route.query.menu_id;
        this.getEntityMenu.menu_list.forEach((e) => {
          if (e._id == id) {
            menu = e;
          } else if (e.children) {
            (e.children || []).forEach((el) => {
              if (el._id == id) {
                menu = el;
              }
            });
          }
        });
        return menu;
      }
      return null;
    },
    getCurrentEntityTemplates() {
      if (this.currentEntity && this.currentEntity.templates) {
        if (this.isApplicationUserSide) {
          if (
            this.$route.query?.menu_id &&
            this.getEntityPermission &&
            this.currentMenu
          ) {
            if (
              this.currentMenu?.entity_type == "ENTITY" &&
              this.getEntityPermission.other_entities &&
              this.getEntityPermission.other_entities[
                this.getEntityDataById._id
              ]?.templates
            ) {
              return this.getEntityDataById.templates.filter((e) => {
                if (
                  this.getEntityPermission.other_entities[
                    this.getEntityDataById._id
                  ].templates[e.template_id] &&
                  this.getEntityPermission.other_entities[
                    this.getEntityDataById._id
                  ].templates[e.template_id].indexOf("VIEW") != -1
                ) {
                  return true;
                }
              });
            } else if (
              this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
              this.getEntityPermission.relational_entities &&
              this.getEntityPermission.relational_entities[
                this.getEntityDataById._id
              ]?.templates
            ) {
              return this.getEntityDataById.templates.filter((e) => {
                if (
                  this.getEntityPermission.relational_entities[
                    this.getEntityDataById._id
                  ].templates[e.template_id] &&
                  this.getEntityPermission.relational_entities[
                    this.getEntityDataById._id
                  ].templates[e.template_id].indexOf("VIEW") != -1
                ) {
                  return true;
                }
              });
            }
            return [];
          } else if (this.getEntityPermission?.other_entities) {
            return this.getEntityDataById.templates.filter((e) => {
              if (
                this.getEntityPermission.other_entities[
                  this.getEntityDataById._id
                ].templates[e.template_id] &&
                this.getEntityPermission.other_entities[
                  this.getEntityDataById._id
                ].templates[e.template_id].indexOf("VIEW") != -1
              ) {
                return true;
              }
            });
          }
          return this.currentEntity.templates;
        } else {
          let selectedTemplates = [];
          if (this.currentStepData?.settings?.selectedTemplates) {
            selectedTemplates = this.currentStepData.settings.selectedTemplates;
          }
          if (
            this.$route.query?.filter &&
            this.getSingleFilterData?.permissions?.templates &&
            this.currentEntity._id == this.getSingleFilterData.entity_id
          ) {
            return this.currentEntity.templates.filter(
              (e) =>
                this.getSingleFilterData?.permissions?.templates.indexOf(
                  e.template_id
                ) != -1
            );
          }
          return this.currentEntity.templates.filter(
            (e) =>
              !selectedTemplates.length ||
              selectedTemplates.indexOf(e.template_id) != -1
          );
        }
      }
      return [];
    },
    templateDataId() {
      if (
        this.activeTab?.template_id &&
        this.getEntityPrimaryData?.templates_data
      ) {
        let templateDataId = (
          this.getEntityPrimaryData.templates_data || []
        ).find((e) => e.template_id == this.activeTab.template_id);
        if (templateDataId?.template_data_id) {
          return templateDataId?.template_data_id;
        }
      }
      return null;
    },
    allTemplates() {
      if (
        this.getCurrentEntityTemplates &&
        this.getCurrentEntityTemplates?.length
      ) {
        let selectedTemplates = [];
        if (this.currentStepData?.settings?.selectedTemplates) {
          selectedTemplates = this.currentStepData.settings.selectedTemplates;
        }
        return this.getCurrentEntityTemplates
          .filter(
            (e) =>
              !selectedTemplates.length ||
              selectedTemplates.indexOf(e.template_id) != -1
          )
          .map((te) => {
            return { ...te.templateInfo };
          });
      }
      return [];
    },
    checkIsFilteredData() {
      if (
        (this.$route.query?.filter &&
          this.getSingleFilterData &&
          this.getEntityDataById &&
          this.getEntityDataById._id == this.getSingleFilterData.entity_id) ||
        this.isApplicationUserSide
      ) {
        return false;
      }
      return true;
    },
    getRepeatableContainerStyle() {
      return "max-height: calc(100vh - 300px) !important; overflow-x: hidden !important;";
    },
    getCurrentEntityExistedData() {
      return (step) => {
        if (
          step?.settings?.isLoginEntity &&
          this.getAuthenticatedUser?.account_data_id &&
          this.formbuilderDetails?.form_builders_owner == "ENTITY"
        ) {
          return this.getAuthenticatedUser.account_data_id;
        }
        const formbuilderTemplateData = this.formbuilderDetails?.steps.find(
          (e) => {
            let id = e.entity && e.entity._id ? e.entity._id : e.entity;
            if (id == step.entity._id || id == step.entity) {
              return e;
            }
          }
        );
        const currenctTemplateData = (
          this.currentFormbuilderData?.formbuilder_steps_data || []
        ).find((e) => {
          let id =
            e.entity_id && e.entity_id._id ? e.entity_id._id : e.entity_id;
          if (id == step.entity._id || id == step.entity) {
            return e;
          }
        });
        if (this.formbuilderDataId && currenctTemplateData) {
          return (
            currenctTemplateData?.entity_data_id ||
            currenctTemplateData?.entityDataId
          );
        }
        return (
          formbuilderTemplateData?.entityDataId ||
          formbuilderTemplateData?.entity_data_id ||
          step.entityDataId
        );
      };
    },
    getAllSteps() {
      if (this.formbuilderDetails?.steps) {
        return this.formbuilderDetails.steps;
      }
      return [];
    },
    entityData() {
      return this.currentEntityData;
    },
    checkApprovalFormBuilder() {
      return this.formbuilderDetails?.is_approval_form || false;
    },
    currentStepRelationship() {
      return (this.getEntityAllRelationshipData || []).find(
        (e) =>
          e.relationship_id == this.currentStepData?.entity?._id ||
          e.relationship_id == this.currentStepData.entity
      );
    },
    checkFormbuilderEntityDataUpdate() {
      if (
        this.checkApprovalFormBuilder &&
        !this.currentFormbuilderData?._id &&
        (this.currentStepData?.settings?.isLoginEntity ||
          (this.currentStepData?.settings?.applyRelationship &&
            this.currentStepRelationship))
      ) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    this.fetchFormBuilderConfiguration();
  },
  created() {
    this.$bus.$on("refreshFormbuilderAfterPayment", async (data) => {
      console.log("refreshFormbuilder from pay button after payment", data);
      this.loading = true;
      //await this.fetchNewEntityData();
      this.loadingText = "Refreshing the page for payment information";
      await this.entityDataCreated({
        dataId: data.entityDataId,
        moveToNext: false,
        notFetchData: false,
        fromPayAction: true,
      });
      this.showError = false;
      this.showData = false;
      setTimeout(() => {
        this.showData = true;
        // this.loading = false;
        // this.loadingText="Loading.....! ";
      }, 3000);
      this.$bus.$off("refreshFormbuilderAfterPayment");
    });
  },
  methods: {
    async fetchFormBuilderConfiguration() {
      if (
        this.$route.name == "ApEntityExecute" ||
        this.$route.name == "ApPreviewEntityData"
      ) {
        this.isApplicationUserSide = true;
      }
      if (this.$route.query.stepId) {
        if (this.allTemplates.length <= this.$route.query.stepId) {
          this.showError = true;
        } else {
          this.currentStep = this.$route.query.stepId;
        }
      }
      if (this.activeTab?.template_id) {
        this.currentTemplate = this.activeTab.template_id;
      }
      await this.fetchEntityRelationships();
      if (
        this.currentFormbuilderData?.formbuilder_steps_data?.[
          this.currentStepIndex
        ]
      ) {
        this.entityDataId =
          this.currentFormbuilderData?.formbuilder_steps_data?.[
            this.currentStepIndex
          ]?.entity_data_id;
      } else if (this.currentStepData?.settings?.isLoginEntity) {
        this.entityDataId = this.getAuthenticatedUser.account_data_id;
      } else if (this.currentStepData?.settings?.applyRelationship) {
        if (
          this.currentStepRelationship &&
          (this.currentStepRelationship[
            this.currentStepRelationship?.relation_type
          ] == "ONE" ||
            this.currentTemplateIndex > 0) &&
          this.currentStepRelationship?.entity_data_ids &&
          this.currentStepRelationship.entity_data_ids.length
        ) {
          this.entityDataId =
            this.currentStepRelationship?.entity_data_ids &&
            this.currentStepRelationship.entity_data_ids.length
              ? this.currentStepRelationship.entity_data_ids[
                  this.currentStepRelationship.entity_data_ids.length - 1
                ]
              : "";
        }
      }
      if (this.entityDataId) {
        await this.fetchNewEntityData();
      }
      this.showData = true;
    },
    async updateRelationData(id) {
      if (
        this.currentStepRelationship &&
        this.getAuthenticatedUser?.account_data_id
      ) {
        let data;
        if (this.currentStepRelationship.owner_type == "PARENT") {
          data = {
            parent_entity_id: this.currentStepRelationship?.parent_entity_id,
            child_entity_id: this.currentStepRelationship?.child_entity_id,
            parent_entity_data_id: this.getAuthenticatedUser.account_data_id,
            child_entity_data_ids: [id],
          };
        } else {
          if (this.currentStepRelationship?.parent_relation_type == "MANY") {
            data = {
              parent_entity_id: this.currentStepRelationship?.parent_entity_id,
              child_entity_id: this.currentStepRelationship?.child_entity_id,
              parent_entity_data_id: id,
              child_entity_data_ids: [
                this.getAuthenticatedUser.account_data_id,
              ],
            };
          } else {
            if (
              this.currentStepRelationship.parent_entity_id ==
              this.currentEntity?._id
            ) {
              data = {
                parent_entity_id: this.currentStepRelationship.parent_entity_id,
                child_entity_id: this.currentStepRelationship.child_entity_id,
                parent_entity_data_id: id,
                child_entity_data_ids: [
                  this.getAuthenticatedUser.account_data_id,
                ],
              };
            } else {
              data = {
                parent_entity_id: this.currentStepRelationship.parent_entity_id,
                child_entity_id: this.currentStepRelationship.child_entity_id,
                parent_entity_data_id:
                  this.getAuthenticatedUser.account_data_id,
                child_entity_data_ids: [id],
              };
            }
          }

          if (
            this.currentStepRelationship?.representation == "FIELD" &&
            this.unAssignedFieldDetails
          ) {
            data.assign_field_data = [];
            this.unAssignedFieldDetails.forEach((field) => {
              data.assign_field_data.push({
                ...field,
                ...{
                  entity_data_id: this.getAuthenticatedUser.account_data_id,
                },
              });
            });
          }
        }
        await this.$store.dispatch(
          "entityRelationships/assignEntityRelationshipData",
          data
        );
        if (this.getEntityRelationshipDataAssignStatus) {
          if (this.formbuilderDetails?.entity_id) {
            let params = {
              parent_entity_id: this.formbuilderDetails.entity_id,
              parent_entity_data_id: this.getAuthenticatedUser?.account_data_id,
            };

            await this.$store.dispatch(
              "entityRelationships/fetchEntityAllRelationshipData",
              params
            );
          }
        }
      }
      //check for field relationships
      if (
        this.getEntityAllRelationshipData &&
        this.getEntityAllRelationshipData.length
      ) {
        let fieldTypeRelationfields = this.getEntityAllRelationshipData.filter(
          (e) => e.representation == "FIELD"
        );
        if (fieldTypeRelationfields && fieldTypeRelationfields.length) {
          let relationshipsData = [
            ...(this.getEntityAllRelationshipData || []),
          ];

          relationshipsData = relationshipsData.map((e) => {
            if (e.parent_entity_id == this.currentEntity?._id) {
              e.owner_type = "PARENT";
              e.relationship_title = e.child_relation_title;
              e.relationship_slug = makeSlug(e.relationship_title);
              e.relationship_id = e.child_entity_id;
            } else {
              e.owner_type = "CHILD";
              e.relationship_title = e.parent_relation_title;
              e.relationship_slug = makeSlug(e.relationship_title);
              e.relationship_id = e.parent_entity_id;
            }
            e.parent_entity_data_id = this.entityDataId;
            return e;
          });

          relationshipsData = relationshipsData.filter((e) => {
            if (e.owner_type == "CHILD" && !e.is_bidirectional) {
              return false;
            } else {
              return true;
            }
          });
          let allFields = this.getAllFieldsFormEntity(this.currentEntity);
          let fieldsHasRelationships = allFields.filter((field) => {
            let relationship = relationshipsData.find(
              (x) =>
                x.field_id == field._id && x.child_entity_id == field.entity_id
            );

            return relationship &&
              this.getCurrentStep.template_id == field.template_id
              ? true
              : false;
          });
          if (fieldsHasRelationships && fieldsHasRelationships.length) {
            let toAssignRelationshipsData = this.prepareRelationshipData(
              allFields,
              relationshipsData,
              this.entityDataId,
              this.getAuthenticatedUser &&
                this.getAuthenticatedUser.account_data_id
                ? this.getAuthenticatedUser.account_data_id
                : null,
              this.currentTemplateData
            );
            if (toAssignRelationshipsData.length) {
              await this.$store.dispatch(
                "entityRelationships/assignFieldsEntityRelationshipData",
                toAssignRelationshipsData
              );
            }
          }
        }
      }
    },
    getAllFieldsFormEntity(entity) {
      if (entity && entity.templates && entity.templates.length) {
        let result = [];
        entity.templates.forEach((e) => {
          if (e && e.templateInfo) {
            result = [...result, ...this.getTemplateFields(e.templateInfo)];
          }
        });
        return result;
      }
      return [];
    },
    getTemplateFields(data) {
      let fields = [];
      (data.sections || []).forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    setEntityRelationshipData() {
      if (this.currentStepData?.settings?.applyRelationship) {
        let currentStepRelationship = (
          this.getEntityAllRelationshipData || []
        ).find(
          (e) =>
            e.relationship_id == this.currentStepData?.entity?._id ||
            e.relationship_id == this.currentStepData.entity
        );
        if (
          currentStepRelationship?.owner_type == "CHILD" &&
          currentStepRelationship?.representation == "FIELD"
        ) {
          if (this.getCurrentStep?.templateInfo?.sections[0]?.fields) {
            this.getCurrentStep?.templateInfo?.sections[0]?.fields.forEach(
              (element) => {
                if (
                  element?.inputType == "ENTITY" &&
                  element?.entity_id == this.formbuilderDetails?.entity_id &&
                  this.getAuthenticatedUser?.account_data_id
                ) {
                  this.unAssignedFieldDetails = [
                    ...this.unAssignedFieldDetails,
                    ...[
                      {
                        key: element.key,
                        template_id:
                          this.getCurrentStep?.templateInfo?._id ||
                          this.getCurrentStep.template_id,
                        value: this.getAuthenticatedUser?.account_data_id,
                      },
                    ],
                  ];
                  this.form = {
                    ...{},
                    ...{
                      [element.key]: this.getAuthenticatedUser.account_data_id,
                    },
                  };
                }
              }
            );
          }
        }
      }
    },
    async fetchNewEntityData(createnew) {
      this.loading = true;
      await this.$store.dispatch("entities/fetchEntityPrimaryDataById", {
        id: this.entityDataId,
      });
      if (this.getEntityPrimaryData?._id) {
        this.currentEntityData = this.getEntityPrimaryData;
      }
      if (!this.$route.query.formbuilder_data_id && createnew) {
        await this.entityDataCreated({
          dataId: this.entityDataId,
          moveToNext: false,
          notFetchData: true,
        });
      }
      this.loading = false;
    },
    async fetchEntityRelationships() {
      this.loading = true;
      this.loadingText = "Fetching Entity relationships..";
      //Fetch application user permissions
      let [fetchedEntityRelationships] = await Promise.all([
        fetchEntityRelationships({
          entity_id: this.currentEntity?._id,
        }),
        // this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
        //   entity_id: this.currentEntity?._id,
        // }),
        this.$store.dispatch(
          "entityRelationships/fetchEntityAllRelationshipData",
          {
            parent_entity_id: this.formbuilderDetails.entity_id,
            parent_entity_data_id: this.getAuthenticatedUser?.account_data_id,
          }
        ),
      ]);
      if (fetchedEntityRelationships && fetchedEntityRelationships?.length) {
        this.fetchedRelationships = [...(fetchedEntityRelationships || [])];
        this.setRelationshipDetails();
      }
      this.loading = false;
    },
    setRelationshipDetails() {
      this.relationshipsData = [...(this.fetchedRelationships || [])];

      // we have to add type owner of relationship
      this.relationshipsData = this.relationshipsData.map((e) => {
        if (e.parent_entity_id == this.currentEntity?._id) {
          e.owner_type = "PARENT";
          e.relationship_title = e.child_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.child_entity_id;
        } else {
          e.owner_type = "CHILD";
          e.relationship_title = e.parent_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.parent_entity_id;
        }
        e.parent_entity_data_id = this.entityDataId;
        return e;
      });

      this.relationshipsData = this.relationshipsData.filter((e) => {
        if (e.owner_type == "CHILD" && !e.is_bidirectional) {
          return false;
        } else {
          return true;
        }
      });

      this.$store.dispatch(
        "entityRelationships/updateCurrentEntityRelationships",
        this.relationshipsData
      );
      this.relationshipsData = this.relationshipsData.filter(
        (e) => e.owner_type == "CHILD" || e.representation == "TAB"
      );
    },
    async entityDataCreated(data) {
      this.loading = true;
      let dataId = data.dataId;
      this.entityDataId = dataId;
      let moveToNext = data.moveToNext;
      let params = await this.prepareParamDataForEntity(dataId);
      await this.$store.dispatch(
        "formbuilderData/createFormbuilderDataByTemplateData",
        params
      );
      if (this.getFormbuilderDataCreateByTemplateStatus) {
        this.loading = false;
        if (!moveToNext) {
          let query = this.getNavigationQuery(this.$route.query);
          query["formbuilder_data_id"] = this.getNewFormbuilderData._id;
          this.$router.push({
            name: this.$route.name,
            query: query,
            params: this.$route.params,
          });
          this.$emit("fetchFormbuilderData", this.getNewFormbuilderData._id);
          if (!data.notFetchData) {
            await this.fetchNewEntityData(false);
          }
          return;
        }
        if (
          this.getNewFormbuilderData &&
          this.getNewFormbuilderData.formbuilder_data_status === "COMPLETED" &&
          this.currentStepIndex == this.getAllSteps.length - 1
        ) {
          this.moveToSuccessPage();
        } else {
          this.moveToNextFormBuilderStep();
        }
      } else {
        this.loading = false;
        let message = "";
        if (this.getNewFormbuilderDataError) {
          message =
            this.getNewFormbuilderDataError.critical_error ||
            this.getNewFormbuilderDataError.message;
        }
        this.$notify.error({
          title: "Error",
          message: message,
        });
      }
    },
    moveToSuccessPage() {
      if (
        this.formbuilderDetails.settings &&
        this.formbuilderDetails.settings.success_and_redirect_page &&
        this.formbuilderDetails.settings.success_and_redirect_page
          .redirect_to &&
        this.formbuilderDetails.settings.success_and_redirect_page
          .redirect_to_url
      ) {
        if (
          this.isValidUrl(
            this.formbuilderDetails.settings.success_and_redirect_page
              .redirect_to_url
          ) &&
          this.isValidHttpUrl(
            this.getValidUrl(
              this.formbuilderDetails.settings.success_and_redirect_page
                .redirect_to_url
            )
          )
        ) {
          window.location.href = this.getValidUrl(
            this.formbuilderDetails.settings.success_and_redirect_page
              .redirect_to_url
          );
        } else {
          this.gotoSuccessPage();
        }
      } else {
        this.gotoSuccessPage();
      }
    },
    async moveToNextFormBuilderStep() {
      let query = this.getNavigationQuery(this.$route.query);
      query["formbuilder_data_id"] =
        this.$route.query.formbuilder_data_id || this.getNewFormbuilderData._id;
      query["route"] = Math.floor(Math.random() * 100000);
      this.$router.push({
        name: this.$route.name,
        query: query,
        params: this.$route.params,
      });
      this.$emit("fetchFormbuilderData", this.getNewFormbuilderData._id);
      this.gotoNextTemplate(this.currentStepIndex + 1);
    },
    gotoNextTemplate(step) {
      this.$emit("goto-next", step);
    },
    getValidUrl(url) {
      if (!url.includes("http://") && !url.includes("https://"))
        return "https://" + url;
      return url;
    },
    isValidUrl(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    gotoSuccessPage() {
      let pageName =
        this.$route.name == "CompanyFormbuilderExecuteStep"
          ? "CompanyUserFormbuilderCompleted"
          : this.$route.name == "ApplicationFormbuilderExecuteStep"
          ? "ApplicaionFormbuilderCompleted"
          : "GuestFormbuilderCompleted";
      this.$router.push({
        name: pageName,
        params: {
          formbuilder_code: this.formbuilderDetails.code,
          formbuilder_details_id: this.formbuilderDetails._id,
          formbuilder_data_id: this.getNewFormbuilderData._id,
        },
      });
    },
    async prepareParamDataForEntity(entityData) {
      let is_approval_form =
        this.checkApprovalFormBuilder &&
        this.$route.query &&
        this.$route.query.formbuilder_data_id &&
        this.currentFormbuilderData &&
        this.currentFormbuilderData.approval_users &&
        this.getAuthenticatedUser._id == this.currentFormbuilderData.contact &&
        this.currentFormbuilderData.approval_status == "REJECTED"
          ? true
          : false;
      return {
        formbuilder_details_id: this.formbuilderDetails._id,
        entity_id: this.currentEntity?._id,
        entity_data_id: entityData
          ? entityData
          : this.getCurrentEntityExistedData(this.getCurrentStep),
        formbuilder_step_id: this.getCurrentStep._id,
        template_completion_status: true,
        steps_data: this.getStepsDataForExistedFormbuilderData(entityData),
        contact: this.$route?.query?.user_id || this.getAuthenticatedUser._id,
        schedule_time: this.$route.query?.schedule_time || new Date(),
        time_interval: new Date(),
        entity_completion:
          this.getCurrentStep && this.getCurrentStep.has_next ? false : true,
        formbuilder_data_id:
          this.$route.query && this.$route.query.formbuilder_data_id
            ? this.$route.query.formbuilder_data_id
            : null,
        template_id: "",
        ...(this.$route.query?.schedule_id && {
          schedule_id: this.$route.query?.schedule_id,
        }),
        is_approval_form: is_approval_form,
        ...(is_approval_form && {
          approval_users: this.resetApprovers(
            this.currentFormbuilderData.approval_users || []
          ),
        }),
      };
    },
    resetApprovers(users) {
      return users.map((e) => {
        if (e.approved_status) {
          delete e.approved_status;
        }
        if (e.approved_at) {
          delete e.approved_at;
        }
        if (e.approved_by) {
          delete e.approved_by;
        }
        if (e.rejected_status) {
          delete e.rejected_status;
        }
        if (e.approval_status) {
          delete e.approval_status;
        }
        if (e.rejected_at) {
          delete e.rejected_at;
        }
        if (e.rejected_reason) {
          delete e.rejected_reason;
        }
        if (e.rejected_by) {
          delete e.rejected_by;
        }
        return e;
      });
    },
    getStepsDataForExistedFormbuilderData(entityData) {
      if (
        this.currentFormbuilderData &&
        this.currentFormbuilderData.formbuilder_steps_data &&
        this.$route.query &&
        this.$route.query.formbuilder_data_id &&
        this.$route.query.formbuilder_data_id ==
          this.currentFormbuilderData._id &&
        this.currentFormbuilderData.formbuilder_steps_data.length
      ) {
        return this.currentFormbuilderData.formbuilder_steps_data;
      }
      return this.formbuilderDetails.steps.map((e) => {
        if (e.template) {
          e.template_id =
            e.template && e.template._id ? e.template._id : e.template;
        } else if (e.entity) {
          e.entity_id =
            e.entity && e.entity._id
              ? e.entity._id
              : e.entity_id
              ? e.entity_id
              : e.entity;
          if (this.currentEntity?._id == e.entity_id) {
            e.entity_data_id = entityData;
          }
        }
        return e;
      });
    },
    async moveToNextTemplate() {
      if (this.getCurrentStep?.has_next) {
        this.currentStep = this.getCurrentEntityTemplates.findIndex(
          (e) => e.template_id == this.currentTemplate
        );
        let query = this.getNavigationQuery(this.$route.query);
        query["stepId"] = this.currentStep++;
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
        this.showError = false;
        this.showData = false;
        this.loading = true;
        setTimeout(() => {
          this.showData = true;
          this.loading = false;
        }, 1000);
      } else {
        if (this.currentStepIndex == this.getAllSteps.length - 1) {
          this.moveToSuccessPage();
        } else {
          this.moveToNextFormBuilderStep();
        }
      }
    },
    async changeTemplate() {
      this.currentStep = this.getCurrentEntityTemplates.findIndex(
        (e) => e.template_id == this.currentTemplate
      );
      let query = this.getNavigationQuery(this.$route.query);
      if (this.currentStep > -1) {
        query["stepId"] = this.currentStep;
      }
      this.$router.push({
        name: this.$route.name,
        query: query,
      });
      this.showError = false;
      this.showData = false;
      this.loading = true;
      setTimeout(() => {
        this.showData = true;
        this.loading = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.template-error-body {
  text-align: center !important;
  margin-top: 100px;
  color: red;
  font-size: 24px;
  height: 300px;
}

.all-template-container {
  // border: 2px solid #e6e6e6;
  // border-radius: 6px;
  max-width: 100% !important;
  padding: 0px 5px;
  max-height: 100% !important;

  .template-heading {
    .template-title {
      color: #303133;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      margin-left: 5px;
    }
  }
}
</style>
